<style scoped lang="less">
  @import './styles.less';
  .patient-list {
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    .item {
      width: 33.33333%;
      padding: 10px;
      box-sizing: border-box;
    }
  }
</style>

<template>
  <div class="patients-page">
    <div class="container">
      <div class="labels">
        <div class="label active">患者管理</div>
      </div>
      <div class="header">
        <div>
          <fm-input-new placeholder="请输入搜索内容" v-model="search.value">
            <fm-select v-model="search.type" style="width: 90px;" slot="prepend">
              <fm-option value="name" label="姓名"></fm-option>
              <fm-option value="phone" label="手机号"></fm-option>
            </fm-select>
            <span style="cursor: pointer;" @click="loadData" slot="append">查询</span>
          </fm-input-new>
        </div>
        <div>
          <fm-btn @click="chooseData = {}">添加患者</fm-btn>
        </div>
      </div>
      <div class="content patient-list">
        <div class="item" v-for="item in data" :key="item.id">
          <Patient :data="item" @del="onPatientDel" />
        </div>
      </div>
      <fm-page
        :current="pageNum"
        :total="total"
        :page-size="pageSize"
        @change="onPageChange"
        @page-size-change="onPageSizeChange"
        show-total
        show-sizer
        show-elevator
        style="padding: 0 20px;"
      />
    </div>

    <fm-modal :value="chooseData !== null" @cancel="chooseData = null" width="1000px" :maskClosable="false">
      <PatientForm v-if="chooseData !== null" :data="chooseData" @submit="submit"/>
    </fm-modal>
  </div>
</template>

<script>
import { patientRequest } from '@/api'

import Patient from './patient'
import PatientForm from './form'

export default {
  components: { Patient, PatientForm },
  data () {
    return {
      data: [],
      chooseData: null,
      pageNum: 1,
      total: 0,
      pageSize: 9,
      search: {
        type: 'name',
        value: null
      }
    }
  },
  methods: {
    onPageChange (num) {
      this.pageNum = num
      this.loadData()
    },
    onPageSizeChange (size) {
      this.pageNum = 1
      this.pageSize = size
      this.loadData()
    },
    onPatientDel ({id}) {
      const index = this.data.findIndex(v => v.id === id)
      if (index > -1) {
        this.data.splice(index, 1)
      }
    },
    async loadData () {
      let search = {}

      if (this.search.type && this.search.value) {
        search[this.search.type] = this.search.value
      }

      const res = await patientRequest.get(Object.assign({
        pageSize: this.pageSize,
        pageNum: this.pageNum
      }, search))

      this.data = res.data
      this.total = res.total
    },
    async submit (data) {
      if (data.id) {
        await patientRequest.update(data.id, data)
        this.$notice.success('更新成功')
      } else {
        await patientRequest.add(data)
        this.$notice.success('添加成功')
      }
      this.loadData()
      this.chooseData = null
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>
